<template>
  <div v-if="current" class="weather container">
    <div class="weather-current">
      <h4 class="text-center m-2">
        {{ current.WeatherText }}
      </h4>

      <div class="d-flex">
        <div class="ml-auto">
          <weather-icon-svg
            :code="current.WeatherIcon"
            size="100"
            class="mt-5 mr-3"
          />
        </div>
        <div>
          <div class="temp">
            {{ current.ApparentTemperature.Metric.Value }}
          </div>
        </div>
        <div class="mr-auto ml-4">
          <div class="mt-5">
            <i class="fas fa-wind"></i>
            {{ current.Wind.Speed.Metric.Value }}
            <small>
              {{ current.Wind.Speed.Metric.Unit }}
            </small>
          </div>
          <div style="min-width: 100px">
            <i class="far fa-eye"></i>
            {{ current.Visibility.Metric.Value }}
            <small>
              {{ current.Visibility.Metric.Unit }}
            </small>
          </div>
        </div>
      </div>

      <div class="text-center info-arduino p-2 m-3">
        <i class="fas fa-robot mr-1"></i>
        Temp:

        <stat-btn
          class="btn btn-outline-success btn-sm mr-2"
          variable="t18"
          driver="piec"
        >
          Wejście:
          {{ outsideTemp[0] }}
        </stat-btn>

        <stat-btn
          class="btn btn-outline-success btn-sm"
          variable="zew"
          driver="swiatlo"
        >
          Kotłownia:
          {{ outsideTemp[1] }}
        </stat-btn>
      </div>

      <div class="text-center info">
        <small class="mr-2">UV: {{ current.UVIndex }},</small>
        <small
          >Ciśnienie {{ current.Pressure.Metric.Value }} mbar ({{
            current.PressureTendency.LocalizedText
          }})</small
        >
      </div>
    </div>

    <div class="mt-3 mt-md-5" v-if="forecast">
      <AppBox
        :header="dayDate(day.Date)"
        v-for="day in forecast.DailyForecasts"
        :key="day.date"
      >
        <div class="text-center">
          <small>{{ day.Day.LongPhrase }}</small>
        </div>

        <div class="d-flex justify-content-center mt-3 mb-2">
          <weather-icon-svg
            :code="day.Day.Icon"
            size="75"
            class="mr-lg-2 mt-1"
          />
          <div>
            <div class="d-flex">
              <h2 class="mr-2 mr-lg-3 mt-1">
                {{ day.RealFeelTemperature.Maximum.Value }}
              </h2>
              <div class="minmax">
                <p class="m-0">
                  <i class="fas fa-angle-up mr-1"></i>
                  {{ day.Temperature.Maximum.Value }}
                </p>
                <p class="m-0">
                  <i class="fas fa-angle-down mr-1"></i>
                  {{ day.Temperature.Minimum.Value }}
                </p>
              </div>
            </div>
          </div>
          <div class="ml-3 ml-lg-5 last">
            <p class="m-0">
              <i class="fas fa-tint"></i>
              {{ day.Day.Rain.Value }}
              {{ day.Day.Rain.Unit }}
            </p>
            <p class="m-0">
              <i class="fas fa-wind"></i> {{ day.Day.Wind.Speed.Value }}
              <small>{{ day.Day.Wind.Speed.Unit }}</small>
            </p>
          </div>
        </div>
      </AppBox>
    </div>

    <p class="text-center">
      <small>Update: {{ updateTime }}</small>
    </p>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import 'dayjs/locale/pl'

import { mapState } from 'vuex'
import WeatherIconSvg from '../components/helpers/weather-icon-svg'
import StatBtn from '../components/helpers/stat-btn.vue'

export default {
  name: 'WeatherView',

  components: {
    WeatherIconSvg,
    StatBtn
  },

  computed: {
    ...mapState(['weather', 'driversData']),

    forecast() {
      return this.weather.forecast
    },

    current() {
      return this.weather.current && this.weather.current[0]
    },

    updateTime() {
      return dayjs(this.current.LocalObservationDateTime).format('HH:mm')
    },

    outsideTemp() {
      if ('piec' in this.driversData && 'swiatlo' in this.driversData) {
        return [this.driversData.piec.t18, this.driversData.swiatlo.zew]
      }
      return ''
    }
  },

  methods: {
    dayName(date) {
      return dayjs(date)
        .locale('pl')
        .format('dddd')
        .toUpperCase()
    },

    dayDate(date) {
      return (
        this.dayName(date) + ` <strong>${dayjs(date).format('D/MM')}</strong>`
      )
    }
  },
  mounted() {}
}
</script>

<style lang="scss"></style>
